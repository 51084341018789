<template>
  <section>
    <b-card v-for="(azProduct,azIndex) in _products" :key="azIndex">
      <MatchingProduct
          v-for="(product,pIndex) in azProduct.products2"
          :key="pIndex"
          :azProduct="azProduct"
          :product="product"
      />
    </b-card>

    <b-card>
      <b-button-group>
        <b-button title="Seçili olanları eşleştir" @click="setSelectedMatching"
                  variant="success">Eşleştir
        </b-button>
      </b-button-group>
    </b-card>

  </section>
</template>

<script>
import {mapGetters} from "vuex";
import MatchingProduct from "@/views/amazon/matching/MatchingProduct";

export default {
  name: "MatchingProducts",
  components: {MatchingProduct},
  computed: {
    ...mapGetters('azProductMatching', ["_products"])
  },
  methods: {
    setSelectedMatching() {
      let ids = [];
      this._products.forEach(azProduct => {
        azProduct.products2.forEach(product => {
          if (product.matching) {
            ids.push({
              az_product_id: azProduct.id,
              product_id: product.id,
              status: 2
            })
          }
        });
      })
      this.$store.dispatch('azProductMatching/setProductsPivotStatus', ids);
    }
  },
}
</script>

<style scoped>

</style>
