<template>
  <b-card>
    <b-row>
      <b-col md="2" class="imageContainer m-0 p-0">
        <div v-if="azProduct.images">
          <div class="clearfix" v-if="azProduct.images[0]" @click="showImages">
            <b-img :src="azProduct.images[0].url" width="150"/>
          </div>
        </div>
      </b-col>

      <b-col md="2" class="imageContainer m-0 p-0">
        <div v-if="product.images">
          <div class="clearfix" v-if="product.images[0]" @click="showImages">
            <b-img :src="'https://api.kitapfirsatlari.com/' + product.images[0].url" width="150"/>
          </div>
        </div>
      </b-col>

      <b-col md="3">

        <a :href="'https://www.amazon.com.tr/dp/'+azProduct.merchant_sku" target="_blank">
          <feather-icon icon="ExternalLinkIcon"/>
        </a> {{ azProduct.product_mp_id }}
        <b-badge variant="info" @click="productMatching">Ürün No Ekle</b-badge>
        <br>
        <br>
        <b-badge :variant="azProduct.merchant_sku==product.barcode?'success':''">
          {{ azProduct.merchant_sku }}
        </b-badge>
        <br>
        <b-badge :variant="azProduct.merchant_barcode==product.barcode?'success':''">
          {{ azProduct.merchant_barcode }}
        </b-badge>
        <br>
        <b-badge :variant="azProduct.isbn==product.barcode?'success':''">
          ISBN: {{ azProduct.isbn }}
        </b-badge>
        <br>
        <b-badge :variant="azProduct.gtin==product.barcode?'success':''">
          GTIN: {{ azProduct.gtin }}
        </b-badge>
        <br>
        <b-badge :variant="azProduct.upc==product.barcode?'success':''">
          UPC: {{ azProduct.upc }}
        </b-badge>
        <br>
        <b-badge :variant="azProduct.ean==product.barcode?'success':''">
          EAN: {{ azProduct.ean }}
        </b-badge>

        <br>

        <div style="word-wrap:break-word;"
             :class="azProduct.product_name==product.name?'bg-success text-white':'bg-secondary text-white'">
          {{ azProduct.product_name }}
        </div>

        <div v-if="azDetail.salesRanks">
          <b-button-group size="sm">
            <a v-for="rank in azDetail.salesRanks[0].classificationRanks"
               target="_blank"
               class="btn btn-sm btn-outline-primary"
               :href="rank.link"
            > {{ rank.title }} ({{ rank.rank }})
            </a>
          </b-button-group>
          <br>
          <b-button-group size="sm">
            <a v-for="rank in azDetail.salesRanks[0].displayGroupRanks"
               target="_blank"
               class="btn btn-sm btn-outline-primary"
               :href="rank.link"
            > {{ rank.title }} ({{ rank.rank }})
            </a>
          </b-button-group>
        </div>


      </b-col>

      <b-col md="3">
        <a href="#" target="_blank">
          <feather-icon icon="ExternalLinkIcon"/>
        </a> {{ product.id }}
        <b-badge variant="info" @click="azMatching">az SKU Ekle</b-badge>
        <br>

        <br>
        <b-badge :variant="azProduct.merchant_barcode==product.barcode?'success':''">
          {{ product.barcode }}
        </b-badge>
        <br>
        <b-badge v-if="product.brand">{{ product.brand.name }}</b-badge>
        <b-badge v-else-if="product.publishers[0]">
          {{ product.publishers[0].publisher_name }}
        </b-badge>
        <b-badge v-else>-</b-badge>
        <br>

        <b-badge v-if="product.categories[0]">{{ product.categories[0].name }}</b-badge>
        <b-badge v-else>-</b-badge>
        <br>

        <div style="word-wrap:break-word;"
             :class="azProduct.product_name==product.name?'bg-success text-white':'bg-secondary text-white'">
          {{ product.name }}
        </div>

        <b-button-group size="sm">
          <a
              v-for="supplierProduct in product.supplier_products"
              :key="supplierProduct.id"
              class="btn btn-sm btn-outline-primary"
              target="_blank"
              :href="supplierProduct.url"
          > {{ supplier_name(supplierProduct.supplier_id) }} - {{ supplierProduct.price }} {{
              supplierProduct.currency
            }} - {{ supplierProduct.stock }}
            Ad.
          </a>
        </b-button-group>
      </b-col>

      <b-col md="2">
        <div>
          <b-form-checkbox v-model="product.matching" class="custom-control-success" name="check-button" switch/>
        </div>
        <b-badge class="mt-1" :variant="matching_status_color(product.pivot.status)">
          {{ matching_status(product.pivot.status) }}
        </b-badge>
        <br>
        <b-button-group class="mt-1">
          <b-button @click="toSendStatus(2)" size="sm" variant="success"> Onayla</b-button>
          <b-button @click="toSendStatus(5)" size="sm" variant="warning"> Ertele</b-button>
          <b-button @click="toSendStatus(4)" size="sm" variant="danger"> Reddet</b-button>
        </b-button-group>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import supplierMixins from "@/mixins/supplierMixins";
import matchingStatusMixins from "@/mixins/matchingStatusMixins";

export default {
  name: "MatchingProduct",
  mixins: [supplierMixins, matchingStatusMixins],
  props: {
    azProduct: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    azDetail() {
      if (this.azProduct.detail) {
        return JSON.parse(this.azProduct.detail.detail);
      }
      return {}
    }
  },
  methods: {
    showImages() {

    },
    productMatching() {
      this.$swal({
        title: 'Ürün No Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (productId) => {
          if (!productId) return null
          return this.$store.dispatch('product/addAzMatchingProduct', {productId, sku: this.azProduct.merchant_sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    azMatching() {
      this.$swal({
        title: 'Pazaryeri SKU Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (sku) => {
          if (!sku) return null
          return this.$store.dispatch('product/addAzMatchingProduct', {productId: this.product.id, sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    toSendStatus(statusId) {
      this.product.matching = false;
      let ids = [{
        az_product_id: this.azProduct.id,
        product_id: this.product.id,
        status: statusId
      }]
      this.$store.dispatch('azProductMatching/setProductsPivotStatus', ids);
    }
  }
}
</script>

<style scoped>

</style>
