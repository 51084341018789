<template>
  <section>
    <MatchingSearch/>
    <MatchingProducts/>
  </section>
</template>

<script>
import MatchingSearch from "@/views/amazon/matching/MatchingSearch";
import MatchingProducts from "@/views/amazon/matching/MatchingProducts";

export default {
  name: "MatchingHome",
  components: {MatchingSearch, MatchingProducts},
  created() {
    this.$store.dispatch('productBrand/getAll')
  }
}
</script>

<style scoped>

</style>
